<template>
    <div>
        <b-row class="m-0 align-items-center justify-content-center">
            <b-col class="form-container m-0 p-0" >
                <b-card-title class="text-center mt-2" >
                    Report a bug
                </b-card-title>

                <!-- form -->
                <validation-observer ref="ReportForm">
                    <b-form
                        @submit.prevent="submit"
                    >
                        <!-- name -->
                        <b-form-group label="Name" label-for="name">
                            <validation-provider
                                #default="{ errors }"
                                name="name"
                                vid="name"
                                rules="required"
                            >
                                <b-form-input
                                    autocomplete="off"
                                    id="name"
                                    v-model="name"
                                    :state="
                                        errors.length > 0 ? false : null
                                    "
                                    name="name"
                                    placeholder="Enter your name"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group label="Email" label-for="email">
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                vid="email"
                                rules="required"
                            >
                                <b-form-input
                                    autocomplete="off"
                                    id="email"
                                    v-model="email"
                                    :state="
                                        errors.length > 0 ? false : null
                                    "
                                    name="email"
                                    placeholder="Enter your email"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- bug -->
                            <b-form-group label="Bug" label-for="bug">
                                <validation-provider
                                    #default="{ errors }"
                                    name="bug"
                                    vid="bug"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        id="bug"
                                        v-model="bug"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="bug"
                                        placeholder="Enter the bug"
                                        rows="5"
                                        max-rows="5"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                        <!-- submit buttons -->
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                            :disabled="isInProcess"
                        >
                            <b-spinner v-if="isInProcess" small />
                            Report 
                        </b-button>
                    </b-form>
                </validation-observer>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import _ from "lodash"
import axios from "axios";
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardTitle,
    BForm,
    BButton,
    BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations"

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BCardTitle,
        BForm,
        BButton,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            name:"",
            email: "",
            bug:"",

            // validation rules
            required,
            email,

            // button loading animation flag
            isInProcess: false,
        };
    },
    computed: {

    },
    methods: {
        submit() {
            this.$refs.ReportForm.validate().then((success) => {
                if (success) {
                    this.isInProcess = true;
                    const params = {
                        name: this.name,
                        email: this.email,
                        bug: this.bug
                    }

                    axios
                        .post(`${process.env.VUE_APP_URL}/error-form`, params, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    "accessToken"
                                )}`,
                            },
                        })
                        .then((response) => {
                            console.log(response)
                            this.isInProcess = false;

                        })
                        .catch((error) => {
                            this.validationErrors = _.flatten(
                                Object.values(error.response.data)
                            );
                            this.isInProcess = false;
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
.form-container{
    max-width: 500px;
}
</style>
